




.Toastify__toast-container--bottom-right {

    margin-bottom : 60vh;

}

.loading__btn {

display        : flex;
justify-content : center;
align-items     : center;
background     :  #071595;
font-size       : 16px;
font-weight    : 600;
height         : 3em;
padding        : 0.5em 5em;
border-radius : 4px;
border         : none;
color          : #fff;
text-decoration : none;
max-width : 18em;


}
